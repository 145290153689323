import { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { DateRange } from '@material-ui/pickers';
import Title from '../../components/Title';
import { formatDate } from '../../resources/functions';
import TableCell from '../../components/TableCell';
import { selectCities } from '../../redux/slices/citiesSlice';
import { selectAdmins } from '../../redux/slices/adminsSlice';
import {
  getFeedbacksAsync,
  selectFeedbacks
} from '../../redux/slices/feedbackSlice';
import { selectAuth } from '../../redux/slices/authSlice';
import { Moment } from 'moment';
import FilterHeader, {
  FilterHeaderValues
} from '../../components/Header/FilterHeader';

const PREFIX = 'FeedbacksPage';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

const classes = {
  header: `${PREFIX}-header`,
  addButton: `${PREFIX}-addButton`,
  tableContainer: `${PREFIX}-tableContainer`
};

const Root = styled('div')({
  [`& .${classes.header}`]: {
    display: 'flex',
    alignItems: 'center'
  },
  [`& .${classes.addButton}`]: {
    marginLeft: 20
  },
  [`& .${classes.tableContainer}`]: {
    marginTop: 40,
    marginBotom: 40
  }
});

const initialValues = {
  scooterName: '',
  userId: '',
  dateRange: [null, null] as DateRange<Moment | null>
};

const FeedbacksPage = () => {
  const dispatch = useDispatch();
  const cities = useSelector(selectCities);
  const adminInfo = useSelector(selectAdmins);
  const auth = useSelector(selectAuth);
  const isSuperAdmin = auth.info?.type === 'superAdmin';

  const cityId = useMemo(() => {
    const city = cities.items.find(item => item._id === adminInfo.currentCity);
    if (city) {
      return city._id;
    }
  }, [adminInfo.currentCity, cities.items]);

  const [filters, setFilters] =
    useState<Partial<FilterHeaderValues>>(initialValues);

  const { limit, total, items, loading } = useSelector(selectFeedbacks);
  const [page, setPage] = useState(1);

  const onChangePage = useCallback((e: any, nextPage: number) => {
    setPage(nextPage);
  }, []);

  useEffect(() => {
    dispatch(
      getFeedbacksAsync({ page, limit, cityId: cityId ?? '', ...filters })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, cityId, filters]);

  const handleFormSubmit = (values: Partial<FilterHeaderValues>) => {
    const isValidDateRange =
      values.dateRange?.[0]?.isValid?.() && values.dateRange?.[1]?.isValid?.();

    const dateFrom = isValidDateRange
      ? values.dateRange![0]!.format(DATE_FORMAT)
      : undefined;

    const dateTo = isValidDateRange
      ? values.dateRange![1]!.format(DATE_FORMAT)
      : undefined;

    const dateObj = isValidDateRange
      ? {
          dateTo: dateTo as string,
          dateFrom: dateFrom as string
        }
      : {};

    setFilters({
      scooterName: values?.scooterName?.length ? values.scooterName : undefined,
      userId: values?.userId?.length ? values.userId : undefined,
      ...dateObj
    });
  };

  return (
    <Root>
      <Grid container direction='column'>
        <Grid item>
          <Title>Відгуки</Title>
        </Grid>
        <Grid item>
          <FilterHeader
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
          />
        </Grid>
      </Grid>
      {!loading && items?.length === 0 ? (
        <Grid
          container
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          marginTop={'50px'}
        >
          <Typography>Немає відгуків</Typography>
        </Grid>
      ) : null}
      {Boolean(items?.length) && (
        <div>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Скутер</TableCell>
                  <TableCell>Оцінка</TableCell>
                  <TableCell>Текст</TableCell>
                  {/* <TableCell>Місто</TableCell> */}
                  <TableCell>Користувач</TableCell>
                  {isSuperAdmin ? <TableCell>Девайс</TableCell> : null}
                  <TableCell>Дата</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items?.map(feedback => (
                  <TableRow hover key={feedback._id}>
                    <TableCell>{feedback.scooter.name}</TableCell>
                    <TableCell>{feedback.rate}</TableCell>
                    <TableCell>{feedback.description}</TableCell>
                    {/* <TableCell>{feedback?.city?.cityName}</TableCell> */}
                    <TableCell>
                      {feedback.user.firstName}
                      <br />
                      {feedback.user.phone}
                    </TableCell>
                    {isSuperAdmin ? (
                      <TableCell>
                        {feedback?.device ?? 'Немає данних'}
                      </TableCell>
                    ) : null}
                    <TableCell>
                      {formatDate(feedback.createdAt, { time: true })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination count={total} onChange={onChangePage} />
        </div>
      )}
    </Root>
  );
};

export default FeedbacksPage;
